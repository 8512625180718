import Button from "./components/Button";
import './App.css';

function App() {
  return (
    <>
    <div 
      className='
        mx-auto text-center grid
        2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1
        2xl:mt-8 xl:mt-6 lg:mt-4 md:mt-12 sm:mt-12 mt-8
      '>
      <main className='2xl:ml-auto xl:-ml-16'>
        <header>
          <img 
            src={'/images/logo-jomar.png'} 
            alt="JOMAR Autopartes" 
            width='auto'
            className='mx-auto 2xl:w-1/3 xl:w-1/4 md:w-1/3 sm:w-1/3 w-1/2'
          />
          <div 
            className='
            bg-img-mobile text-white text-left pl-8 pr-32 
            md:mt-16 sm:mt-0 mt-0
            2xl:hidden xl:hidden lg:hidden md:grid
            w-full h-80
            md:grid-cols-2 grid-cols-1
          '>
            <div className='my-auto md:block hidden'>
              <p className=' text-xl'>
                Somos una empresa líder en venta de refacciones y autopartes.
              </p>
              <p 
                className='
                  font-bold italic 
                  md:text-2xl sm:text-xl text-xl
              '>
                Estamos trabajando para ofrecerte un mejor servicio.
              </p>
            </div>
          </div>
          <div 
            className='
              2xl:hidden xl:hidden lg:hidden md:hidden block
              bg-jomar-blue w-20 h-40 absolute right-0 top-20
              z-index-10 line-mobile
            '
          />
          <div 
            className='
              2xl:hidden xl:hidden lg:hidden md:hidden block
              bg-jomar-blue w-8 h-4 absolute right-12 top-48
              z-index-10 line-mobile
            '
          />
        </header>
        <section 
          className='
            text-white 
            2xl:text-xl md:text-xl
            2xl:mt-16 xl:mt-10 lg:mt-8 md:mt-16 sm:mt-10 mt-10
            2xl:px-0 xl:px-0 lg:px-0 md:px-0 sm:px-8 px-8
          '>
          <div className='md:hidden lg:block block'>
            <p>
              Somos una empresa líder en venta de refacciones y autopartes.
            </p>
            <p 
              className='
                font-bold italic 
                2xl:text-2xl xl:text-xl md:text-2xl sm:text-xl text-xl
            '>
              Estamos trabajando para ofrecerte un mejor servicio.
            </p>
          </div>
          <div className='2xl:mt-16 xl:mt-10 lg:mt-8 md:mt-16 sm:mt-8 mt-8'>
            <div>
              <div>
                <span className='line-start'>¿Necesitas más información?</span>
                <span className='font-bold pl-1 italic line-end'>
                  Contáctanos
                </span>
              </div>
            </div>
          </div>
          <div>
            <img 
              src={'/images/telephone.png'}
              alt='teléfono'
              width='40px'
              className='mx-auto 2xl:mt-8 xl:mt-6 lg:mt-4 md:mt-8 sm:mt-8 mt-8 mb-4'
            />
            <a 
              href='tel:8181268000'
              className='font-bold 2xl:text-3xl xl:text-xl lg:text-xl md:text-3xl sm:text-xl text-xl'
              title='Llamar'
            >
              (81) 8126 8000
            </a>
            <p>
              Lunes a Viernes: 8:00 am a 9:00 pm
            </p>
            <p>
              Sábados: 8:00 am a 9:00 pm / Domingos: 8:00 am a 6:00 pm
            </p>
            <Button 
              text={'LLAMAR AHORA'} 
              onClick={() => {window.open('tel:8181268000', '_self')}} 
            />
          </div>
          <div className='2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-32 mb-32'>
            <img 
              src={'/images/email.png'}
              alt='Email'
              width='40px'
              className='mx-auto 2xl:mt-12 xl:mt-10 lg:mt-8 md:mt-12 sm:mt-8 mt-8 mb-4'
            />
            <a
              href='mailto:atencionaclientes@jomarmx.com'
              className='font-bold 2xl:text-3xl xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl'
              title='Enviar correo'
            >
              atencionaclientes@jomarmx.com
            </a>
            <p>
              Escríbenos, te atenderemos lo antes posible
            </p>
            <p>
              para resolver todas tus dudas
            </p>
            <Button 
              text={'¡ESCRÍBENOS!'} 
              onClick={() => {window.open('mailto:atencionaclientes@jomarmx.com', '_self')}} 
            />
          </div>
          <div 
            className='
            text-sm font-bold mt-32 mb-4
            2xl:hidden xl:hidden lg:hidden block
          '>
            Jomar Autopartes 2021, una empresa de Grupo Jomar.
          </div>
          {/* <div
            className='
              text-sm font-bold absolute w-full 
              md:max-w-none max-w-xs 
              md:right-0 right-4
              md:bottom-8 sm:-bottom-40 -bottom-52
              2xl:hidden xl:hidden lg:hidden
              text-center overflow-ellipsis
          '>
            Jomar Autopartes 2021, una empresa de Grupo Jomar.
          </div> */}
        </section>
      </main>
    </div>
    <div 
      id="img-1" 
      className='
        dots absolute top-0 w-1/4 h-32
        2xl:block xl:block md:hidden sm:hidden hidden
      '
    />
    <div 
      id="img-2" 
      className='
        waves absolute top-1/3 w-1/4 h-32  
        2xl:block xl:block md:hidden sm:hidden hidden
    '/>
    <div 
      id="img-3" 
      className='
      dots-b absolute bottom-0 left-1/4 w-1/4 h-32 
      2xl:block xl:block md:hidden sm:hidden hidden
    '/>
    <div className='bg-img 2xl:block xl:block lg:block md:hidden sm:hidden hidden'></div>
    <div 
      id="img-1-mobile" 
      className='
        dots-mobile absolute top-0 right-0
        2xl:hidden xl:hidden md:block sm:block block
        md:w-1/4 md:h-32 w-1/2 h-32
      '
    />
    <div 
      id="img-2-mobile" 
      className='
        waves-mobile absolute top-2/3 -left-12
        2xl:hidden xl:hidden md:block sm:block block
        md:w-1/2 md:h-40 w-1/3 h-32
      '
    />
    <div 
      id="img-3-mobile" 
      className='
        dots-b absolute left-1/4
        2xl:hidden xl:hidden md:block sm:block hidden
        md:w-1/2 md:h-32
        -bottom-64
      '
    />
    </>
  );
}

export default App;
