import React from 'react'


const Button = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className='
      bg-jomar-red w-56 font-bold h-10 rounded-sm shadow-none mt-4 2xl:text-base xl:text-sm
      transition duration-250 ease-in-out
      hover:shadow-xl
      focus:opacity-90
      '
    >
      { text }
    </button>
  )
}

export default Button